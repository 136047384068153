import React from "react";
import { useState, useEffect, useRef, useContext } from "react";
import {
  menuburger,
  flechegauche,
  star,
  iconSeen,
  iconSeenempty,
} from "../assets";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../firebase.js";
import {
  addDoc,
  collection,
  query,
  orderBy,
  serverTimestamp,
  onSnapshot,
  doc,
  getDoc,
  setDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import Header from "../components/Header";
import { ElementChoisiContext } from "./contexts/ContentContext";
import "../styles/AnimePage.css";
import axios from "axios";
import { useAuth } from "../contexts/AuthContext.js";
import { profilepic } from "../assets/index.js";

function AnimePage() {
  const { id } = useParams();
  const [animeinfos, setAnimeInfos] = useState([]);
  const [movieData, setMovieData] = useState([]);
  const { currentUser } = useAuth();
  const { elementChoisi } = useContext(ElementChoisiContext);
  const [value, setValue] = useState("");
  const [messages, setMessages] = useState([]);
  const [isMounted, setIsMounted] = useState(true);
  const [allAnimeWatched, setAllAnimeWatched] = useState([]);
  const [allMovieWatched, setAllMovieWatched] = useState([]);

  const fondAnimeRef = useRef(null);
  const navigate = useNavigate();

  async function sendMessage(text) {
    try {
      await addDoc(collection(db, "anime", id, "messages"), {
        uid: currentUser.uid,
        email: currentUser.email,
        text: text.trim(),
        timestamp: serverTimestamp(),
      });
    } catch (error) {
      console.error(error);
    }
  }
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    sendMessage(value);
    setValue("");
  };
  const addToWatched = (id) => {
    if (elementChoisi === "movie") {
      if (currentUser) {
        const docRef = doc(db, "users", currentUser.uid);
        let animeinfooos = id ?? "heyyy";

        if (allMovieWatched === undefined || allMovieWatched === []) {
          setDoc(docRef, { watchedMovie: arrayUnion(id) }, { merge: true });
        } else {
          console.log("VOILAAA", id);
          const resultat = allMovieWatched.find((anime) => anime.id === id.id);
          console.log("resultat", resultat);

          console.log("resultat", resultat);

          if (resultat !== undefined) {
            setDoc(docRef, { watchedMovie: arrayRemove(id) }, { merge: true });
          } else {
            console.log("C'est ici le probleme", animeinfooos);

            setDoc(docRef, { watchedMovie: arrayUnion(id) }, { merge: true });
          }
        }
      } else {
        navigate("/login");
      }
    } else {
      if (currentUser) {
        const docRef = doc(db, "users", currentUser.uid);
        let animeinfooos = id ?? "heyyy";

        if (allAnimeWatched === undefined || allAnimeWatched === []) {
          setDoc(docRef, { watchedAnime: arrayUnion(id) }, { merge: true });
        } else {
          console.log("VOILAAA", id);
          const resultat = allAnimeWatched.find((anime) => anime.id === id.id);
          console.log("resultat", resultat);

          console.log("resultat", resultat);

          if (resultat !== undefined) {
            setDoc(docRef, { watchedAnime: arrayRemove(id) }, { merge: true });
          } else {
            console.log("C'est ici le probleme", animeinfooos);

            setDoc(docRef, { watchedAnime: arrayUnion(id) }, { merge: true });
          }
        }
      } else {
        navigate("/login");
      }
    }

    console.log("Anime Saved !");
  };

  async function getTrendingMovieData(content) {
    try {
      const apiKey = "0de11815b0fb35bfb6cffac32f3b1207";
      let resp = await axios.get(
        `https://api.themoviedb.org/3/${content}/${id}?api_key=${apiKey}&language=fr-EU`
      );
      console.log(20, resp.data);
      console.log("heeeyyy");

      const data = resp.data;

      // Attendre que toutes les promesses soient résolues et mettre à jour le state "movieData"

      setMovieData(data);
    } catch (e) {
      console.error(e);
    }
  }
  useEffect(() => {
    console.log("component re up");
    (async () => {
      console.log("JE SUIS LA AUSSI");
      // const docRef = doc(db, "users", currentUser.uid);
      // const animeWatched = await getDoc(docRef);
      // console.log(animeWatched.data().watchedAnime);
      // setAllAnimeWatched(animeWatched.data().watchedAnime);

      if (currentUser) {
        const unsub = onSnapshot(doc(db, "users", currentUser.uid), (doc) => {
          console.log("Current data: ", doc.data().watchedAnime);
          setAllAnimeWatched(doc.data().watchedAnime);
          setAllMovieWatched(doc.data().watchedMovie);
        });
      }
    })();
    {
      elementChoisi === "movie"
        ? getTrendingMovieData("movie")
        : getTrendingMovieData("tv");
    }
    if (currentUser) {
      const unsub = onSnapshot(
        query(collection(db, "anime", id, "messages"), orderBy("timestamp")),
        (querySnapshot) => {
          // Handle query snapshot changes here
          const dataArray = querySnapshot.docs.map((doc) => doc.data());

          console.log("All data: ", dataArray);
          async function getUserProfilePhotos() {
            const updatedDataArray = [];

            for (const data of dataArray) {
              const userId = data.uid; // Assurez-vous d'utiliser la clé appropriée qui contient l'ID de l'utilisateur

              // Effectuer une requête Firestore pour obtenir les informations de l'utilisateur
              const userSnapshot = await getDoc(doc(db, "users", userId));
              if (userSnapshot.exists()) {
                const userData = userSnapshot.data();
                const userProfilePhoto = userData.pdp; // Assurez-vous d'utiliser la clé appropriée qui contient la photo de profil de l'utilisateur
                const userDisplayName = userData.displayname;
                // Ajouter la photo de profil à l'objet de données existant
                const updatedData = {
                  ...data,
                  userProfilePhoto,
                  userDisplayName,
                };
                updatedDataArray.push(updatedData);
              }
            }

            console.log("Updated data: ", updatedDataArray);
            setMessages(updatedDataArray);
          }

          getUserProfilePhotos();
        }
      );

      return () => {
        unsub(); // Unsubscribe from the snapshot listener when the component unmounts
      };
    }

    return () => {
      console.log("hop on demonte le component");
    };
  }, [elementChoisi, id]);
  return (
    <>
      <div className="flex items-center justify-center h-screen">
        <div className="absolute top-0 w-full flex justify-between items-center align-middle sm:mb-[20px] sm:p-6 p-5">
          <div>
            <button
              className="carousel-button previous-button"
              onClick={() => navigate(-1)}
            >
              <img
                className="w-[16px] h-[16px] invert-[100] mb-[-3px]"
                src={flechegauche}
                alt=""
              />
            </button>
          </div>
          <div>
            <div className="text-[rgb(255,255,255)] text-ellipsis overflow-hidden whitespace-nowrap max-w-[250px]">
              {elementChoisi === "movie" ? movieData.title : movieData.name}
            </div>
          </div>
          <div>
            <button
              className="carousel-button previous-button"
              onClick={() => navigate(-1)}
            >
              <img
                className="w-[16px] h-[16px] invert-[100] mb-[-3px]"
                src={menuburger}
                alt=""
              />
            </button>
          </div>
        </div>
        <div className="flex-col justify-center pl-[13%] pr-[13%] pt-[68px] pb-[13%]">
          <div className="">
            <img
              className="rounded-[15px] border-4 w-full h-[400px] object-cover"
              src={`https://image.tmdb.org/t/p/original/${movieData.poster_path}`}
              alt=""
            />
          </div>
          <div className="flex flex-col items-center justify-center mt-[10px] rounded-[15px] border-4 p-[5%]">
            {/* <div className="text-[rgb(255,255,255)] text-ellipsis overflow-hidden whitespace-nowrap max-w-[130px]">
              {elementChoisi === "movie" ? movieData.title : movieData.name}
            </div>
            <div className="text-[rgb(112,112,112)]">&nbsp;|&nbsp;</div> */}
            <div className="flex items-center">
              <img
                className="w-[15px] h-[15px] star-color mt-[-5px]"
                src={star}
                alt=""
              />
              <div className="pl-[3px] text-[13px]">
                {movieData.vote_average}
              </div>
            </div>
            <div className="text-center w-[230px] overflow-hidden whitespace-nowrap text-ellipsis">
              {movieData.genres !== undefined ? (
                movieData.genres.map((genre, index) => (
                  <span
                    className="text-[rgb(184,184,184)] text-[11px]  "
                    key={index}
                  >
                    {genre.name} &nbsp;
                  </span>
                ))
              ) : (
                <div></div>
              )}
            </div>

            {elementChoisi === "movie" ? (
              <div className="w-full flex justify-center items-center mb-3">
                <div class="flex flex-col justify-center items-center relative pl-4">
                  {allMovieWatched !== undefined ? (
                    currentUser &&
                    allMovieWatched.find(
                      (movie) => movie.id === movieData.id
                    ) !== undefined ? (
                      <button
                        className="mark"
                        onClick={() => {
                          addToWatched(movieData);
                        }}
                      >
                        <img src={iconSeen} />
                      </button>
                    ) : (
                      <button
                        className="mark"
                        onClick={() => {
                          addToWatched(movieData);
                        }}
                      >
                        <img src={iconSeenempty} />
                      </button>
                    )
                  ) : (
                    <button
                      className="mark"
                      onClick={() => {
                        addToWatched(movieData);
                      }}
                    >
                      <img src={iconSeenempty} />
                    </button>
                  )}
                </div>
              </div>
            ) : (
              <div className="w-full flex justify-center items-center">
                <div class="flex justify-center items-center flex-col mr-4">
                  <div>{movieData.number_of_episodes}</div>
                  <div className="text-[rgb(184,184,184)] text-[11px]">
                    Episodes
                  </div>
                </div>
                <div class="flex flex-col justify-center items-center relative pl-4">
                  <div>{movieData.number_of_seasons}</div>
                  <div className="text-[rgb(184,184,184)] text-[11px]">
                    Saisons
                  </div>
                  <div class="absolute left-0 top-1/2 -translate-y-1/2 h-[30px] border-r border-gray-300"></div>
                  {allAnimeWatched !== undefined ? (
                    (currentUser &&
                      allAnimeWatched.find(
                        (anime) => anime.id === movieData.id
                      ) !== undefined) ||
                    allMovieWatched.find(
                      (movie) => movie.id === movieData.id
                    ) !== undefined ? (
                      <button
                        className="mark"
                        onClick={() => {
                          addToWatched(movieData);
                        }}
                      >
                        <img src={iconSeen} />
                      </button>
                    ) : (
                      <button
                        className="mark"
                        onClick={() => {
                          addToWatched(movieData);
                        }}
                      >
                        <img src={iconSeenempty} />
                      </button>
                    )
                  ) : (
                    <button
                      className="mark"
                      onClick={() => {
                        addToWatched(movieData);
                      }}
                    >
                      <img src={iconSeenempty} />
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <img
        className={`fondanime ${movieData ? "animate-zoom-out" : ""}`}
        src={`https://image.tmdb.org/t/p/original/${movieData.backdrop_path}`}
        alt=""
      />
      <div className="page-container-ap">
        <div className="carre1">
          <div className="anime-title-ap">
            {elementChoisi === "movie" ? movieData.title : movieData.name}
          </div>
        </div>
        <div className="anime-affiche-container">
          <img
            className="anime-affiche"
            src={`https://image.tmdb.org/t/p/original/${movieData.poster_path}`}
          />
        </div>
        <div className="carre2">
          <div className="message-list">
            {messages.map((item, index) => (
              <div className="msg-container" key={index}>
                {item.userProfilePhoto !== undefined ? (
                  <img className="pdp-msg" src={item.userProfilePhoto} />
                ) : (
                  <img className="pdp-msg" src={profilepic} />
                )}
                <div className="nom-msg-container">
                  <div className="pseudo">
                    {item.userDisplayName !== undefined
                      ? item.userDisplayName
                      : item.email.split("@")[0]}
                  </div>
                  <div>{item.text}</div>
                </div>
              </div>
            ))}
          </div>
          <form onSubmit={handleSubmit} className="message-input-container">
            <input
              type="text"
              placeholder="Enter a message"
              value={value}
              onChange={handleChange}
              className="message-input"
              required
              minLength={1}
            />
            <button type="submit" disabled={value < 1} className="send-message">
              Send
            </button>
          </form>
        </div>
      </div> */}
      {/* <div className="text-white">
        AnimePage id:{id} And:{animeinfos.title}
      </div> */}
    </>
  );
}

export default AnimePage;
