import { React, useState, useContext } from "react";
import "../styles/SearchBar.css";
import { logo, search, menupoints } from "../assets";
import List from "./List";
import PopularAnime from "./PopularAnime";
import { useAuth } from "../contexts/AuthContext.js";
import ReleasedRecently from "./ReleasedRecently";
import WatchList from "./WatchList";
import { Link } from "react-router-dom";
import { ElementChoisiContext } from "./contexts/ContentContext";
import Navbar from "../components/Navbar";

const Search = () => {
  const { elementChoisi } = useContext(ElementChoisiContext);
  const { currentUser } = useAuth();
  const [inputText, setInputText] = useState("");
  let inputHandler = (e) => {
    //convert input text to lower case
    let lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
  };
  return (
    <div className="">
      <Navbar />
      <div className="">
        <div className="flex justify-center">
          <div className="pl-[20px] pr-[20px] search-bar-fixing">
            <input
              className="search-bar"
              type="text"
              placeholder="Type to Search.."
              onChange={inputHandler}
            />
            <img className="search-icon" src={search} alt="" />
          </div>
        </div>
        {inputText === "" ? (
          <div className="pl-[20px] pr-[20px]">
            <div className="flex items-center justify-between mt-[70px]">
              <div className="searchbar-title">
                Popular {elementChoisi === "movie" ? "Movies" : "Animes"}
              </div>
              <img className="menu-points" src={menupoints} alt="" />
            </div>
            <PopularAnime />
            <button className="seemore-btn">See More</button>

            <div>
              <div className="title-image-container">
                <div className="searchbar-title">Released recently</div>
                <img className="menu-points" src={menupoints} alt="" />
              </div>
              <ReleasedRecently />
              <button className="bg-[rgb(36,36,36)] text-[rgb(99,99,99)] w-full pt-[10px] pb-[10px] rounded-[20px] text-[14px] mb-[120px]">
                See More
              </button>
            </div>
          </div>
        ) : (
          <List input={inputText} />
        )}
        {/* <List input={inputText} /> */}
      </div>
    </div>
  );
};

export default Search;
