import React, { useRef } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext.js";

const ForgotPassword = () => {
  const emailRef = useRef();
  const { currentUser, resetPassword } = useAuth();
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  var logocrop = require("../images/logocrop.png");

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setMessage("");
      setError("");
      setLoading(true);
      await resetPassword(emailRef.current.value);
      setMessage("Check your mails for further instructions");
    } catch {
      setError("Failed to reset password");
    }
    setLoading(false);
  }

  return (
    <div className="bg-neutral-900 w-screen h-screen flex justify-center items-center">
      <div className="p-4 xl:w-1/3 2xl:w-1/5 sm:w-1/3 lg:w-1/3 rounded-3xl border border-neutral-700 shadow-md sm:p-6 lg:p-8 bg-neutral-800">
        <form className="space-y-6" onSubmit={handleSubmit}>
          <div className="flex justify-center items-center">
            <a href="/">
              <img src={logocrop} height={120} width={120} />
            </a>
          </div>
          <h2 className="text-2xl text-white font-medium text-center">
            Password Reset
          </h2>
          {error && (
            <div className="">
              <div
                className="p-2 bg-indigo-800 items-center text-indigo-100 leading-none rounded-full flex mb-3"
                role="alert"
              >
                <span className="flex rounded-full bg-indigo-500 uppercase px-2 py-1 text-xs font-bold mr-3">
                  ERROR
                </span>
                <span className="font-semibold mr-2 text-left flex-auto">
                  {error}
                </span>
                <svg
                  className="fill-current opacity-75 h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z" />
                </svg>
              </div>
            </div>
          )}
          {message && (
            <div className="">
              <div
                className="p-2 bg-indigo-800 items-center text-indigo-100 leading-none rounded-full flex mb-3"
                role="alert"
              >
                <span className="flex rounded-full bg-indigo-500 uppercase px-2 py-1 text-xs font-bold mr-3">
                  Success
                </span>
                <span className="font-semibold mr-2 text-left flex-auto">
                  {message}
                </span>
                <svg
                  className="fill-current opacity-75 h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z" />
                </svg>
              </div>
            </div>
          )}
          <div>
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-white"
            >
              Email
            </label>
            <input
              name="email"
              type="email"
              ref={emailRef}
              required
              placeholder="Email"
              className={
                "bg-neutral-700 border border-neutral-600 text-white text-sm rounded-3xl block w-full p-2.5"
              }
            />
          </div>
          {/* <div>
            <label
              htmlFor="password"
              className="block mb-2 text-sm font-medium text-white"
            >
              Password:
            </label>
            <input
              type="password"
              ref={passwordRef}
              required
              placeholder="Password"
              className={
                "bg-neutral-700 border border-neutral-600 text-white text-sm rounded-3xl block w-full p-2.5"
              }
            />
          </div> */}

          <button
            className={
              "w-full border border-white text-white bg-neutral-900 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-3xl text-sm px-5 py-2.5 text-center dark:bg-neutral-700 dark:hover:bg-neutral-900 dark:focus:ring-blue-800"
            }
            disabled={loading}
          >
            Password Reset
          </button>

          <div className="text-white text-center">
            <Link to="/login">Log to your account</Link>
          </div>
          <div className="text-white text-center">
            <Link to="/signup">Need an account? Signup</Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
