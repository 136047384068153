import React, { useEffect, useState, useContext } from "react";
import { db } from "../firebase.js";
import { useAuth } from "../contexts/AuthContext.js";
import "../styles/SearchBar.css";
import { iconSeen, iconSeenempty, star } from "../assets";
import {
  addDoc,
  updateDoc,
  collection,
  doc,
  setDoc,
  getDoc,
  getDocs,
  query,
  arrayUnion,
  onSnapshot,
  arrayRemove,
  increment,
} from "firebase/firestore";
import { ElementChoisiContext } from "./contexts/ContentContext";
import { useNavigate, generatePath } from "react-router-dom";

const BookmarkedList = () => {
  const [allAnimeWatched, setAllAnimeWatched] = useState([]);
  const [allMovieWatched, setAllMovieWatched] = useState([]);
  const { currentUser } = useAuth();
  const [isMounted, setIsMounted] = useState(true);
  const { elementChoisi } = useContext(ElementChoisiContext);
  const navigate = useNavigate();
  const handleProceed = (id) => {
    id && navigate(generatePath("/anime/:id", { id }));
  };

  const addToWatched = (id) => {
    if (currentUser) {
      const docRef = doc(db, "users", currentUser.uid);

      console.log("VOILAAA", id);
      /*TODO BETTER CONDITION FOR ANIME AND MOVIE */
      const resultat = allAnimeWatched.find((anime) => anime.id === id.id);
      console.log("resultatanime", resultat);
      const resultatmovie = allMovieWatched.find((movie) => movie.id === id.id);
      console.log("resultatmovie", resultatmovie);
      if (elementChoisi === "movie") {
        if (resultatmovie !== undefined) {
          setDoc(docRef, { watchedMovie: arrayRemove(id) }, { merge: true });
        } else {
          setDoc(docRef, { watchedMovie: arrayUnion(id) }, { merge: true });
        }
      } else {
        if (resultat !== undefined) {
          setDoc(docRef, { watchedAnime: arrayRemove(id) }, { merge: true });
        } else {
          setDoc(docRef, { watchedAnime: arrayUnion(id) }, { merge: true });
        }
      }
    } else {
      navigate("/login");
    }

    console.log("Anime Saved !");
  };
  useEffect(() => {
    console.log("JE SUIS LA dans watchlist");

    if (currentUser) {
      const unsub = onSnapshot(doc(db, "users", currentUser.uid), (doc) => {
        if (isMounted) {
          console.log("Current data: ", doc.data().watchedAnime);
          setAllAnimeWatched(doc.data().watchedAnime);
          setAllMovieWatched(doc.data().watchedMovie);
        }
      });

      return () => {
        unsub(); // Unsubscribe from the snapshot listener when the component unmounts
      };
    }

    // Return a no-op cleanup function if currentUser is not defined
    return () => {};
  }, [currentUser, isMounted]);
  return (
    <div>
      <div className="font-bold mb-[20px] ml-[20px]">
        {elementChoisi === "movie" ? "Movie List :" : "Anime List :"}{" "}
      </div>
      <div className="flex flex-wrap gap-4">
        {elementChoisi === "movie" ? (
          allMovieWatched === undefined ? (
            <div>Tu n'as pas encore marqué d'anime ^^</div>
          ) : (
            allMovieWatched.map((item, index) => (
              <div
                className="flex flex-row sm:mb-[20px] mb-[15px] mx-3"
                key={index}
              >
                <div className="mr-[10px]">
                  <img
                    onClick={() => handleProceed(item.id)}
                    className="w-32 h-36 rounded-[10%] object-cover cursor-pointer"
                    src={`https://image.tmdb.org/t/p/original/${item.poster_path}`}
                  />
                </div>
                <div className="w-[110px] h-[135px] pt-[5px] pb-[5px] flex flex-col justify-around">
                  <div>
                    <div className="text-[13px] font-bold mb-[6px] text-ellipsis overflow-hidden whitespace-nowrap">
                      {item.name ? item.name : item.title}
                    </div>

                    <div className="text-[13px] text-[#808080]">
                      Saisons : {item.number_of_seasons}
                      <br />
                      Episodes : {item.number_of_episodes}
                    </div>
                  </div>

                  <div className="score-container">
                    <div className="score">{item.vote_average}</div>
                    <div>
                      <img className="star-color" src={star} alt="" />
                    </div>
                    {currentUser &&
                    allMovieWatched.find((anime) => anime.id === item.id) !==
                      undefined ? (
                      <button
                        className="mark"
                        onClick={() => {
                          addToWatched(item);
                        }}
                      >
                        <img src={iconSeen} />
                      </button>
                    ) : (
                      <button
                        className="mark"
                        onClick={() => {
                          addToWatched(item);
                        }}
                      >
                        <img src={iconSeenempty} />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ))
          )
        ) : allAnimeWatched === undefined ? (
          <div>Tu n'as pas encore marqué d'anime ^^</div>
        ) : (
          allAnimeWatched.map((item, index) => (
            <div className="flex flex-row mb-[20px] mx-3" key={index}>
              <div className="mr-[10px]">
                <img
                  onClick={() => handleProceed(item.id)}
                  className="w-32 h-36 rounded-[10%] object-cover cursor-pointer"
                  src={`https://image.tmdb.org/t/p/original/${item.poster_path}`}
                />
              </div>
              <div className="w-[110px] h-[135px] pt-[5px] pb-[5px] flex flex-col justify-around">
                <div>
                  <div className="text-[13px] font-bold mb-[6px] text-ellipsis overflow-hidden whitespace-nowrap">
                    {item.name ? item.name : item.title}
                  </div>

                  <div className="text-[13px] text-[#808080]">
                    Saisons : {item.number_of_seasons}
                    <br />
                    Episodes : {item.number_of_episodes}
                  </div>
                </div>

                <div className="score-container">
                  <div className="score">{item.vote_average}</div>
                  <div>
                    <img className="star-color" src={star} alt="" />
                  </div>
                  {currentUser &&
                  allAnimeWatched.find((anime) => anime.id === item.id) !==
                    undefined ? (
                    <button
                      className="mark"
                      onClick={() => {
                        addToWatched(item);
                      }}
                    >
                      <img src={iconSeen} />
                    </button>
                  ) : (
                    <button
                      className="mark"
                      onClick={() => {
                        addToWatched(item);
                      }}
                    >
                      <img src={iconSeenempty} />
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default BookmarkedList;
