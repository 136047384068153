import React, { useEffect, useState, useContext } from "react";
import { logo, profilepic, notification } from "../assets";
import BookmarkedList from "./BookmarkedList";
import { db } from "../firebase.js";
import {
  addDoc,
  collection,
  doc,
  setDoc,
  getDoc,
  getDocs,
  query,
  deleteDoc,
  updateDoc,
  onSnapshot,
  arrayUnion,
  deleteField,
} from "firebase/firestore";
import "firebase/storage";
import { useAuth } from "../contexts/AuthContext.js";
import { Link } from "react-router-dom";
import { ElementChoisiContext } from "./contexts/ContentContext";

const Bookmarked = () => {
  const [pdp, setPdp] = useState([]);
  const { currentUser } = useAuth();
  const [isMounted, setIsMounted] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const { elementChoisi } = useContext(ElementChoisiContext);
  useEffect(() => {
    if (currentUser) {
      // const unsub = onSnapshot(doc(db, "users", currentUser.uid), (doc) => {
      //   if (isMounted) {
      //     // console.log("Current data: ", doc.data().watchedAnime);
      //     if (doc.data().pdp !== undefined) {
      //       console.log("Current profile picture: ", doc.data().pdp);
      //       setPdp(doc.data().pdp);
      //     } else {
      //       setPdp(undefined);
      //     }
      //   }
      // });
      const getprofilepic = async () => {
        const docRef = doc(db, "users", currentUser.uid);
        try {
          const docSnap = await getDoc(docRef);
          console.log(docSnap.data().pdp);
          setPdp(docSnap.data().pdp);
          setIsLoading(false);
        } catch (error) {
          console.log(error);
        }
      };
      getprofilepic();

      return () => {
        // unsub(); // Unsubscribe from the snapshot listener when the component unmounts
        setIsMounted(false);
      };
    }
  }, []);
  return (
    <div className="mb-[100px] sm:ml-[230px] sm:mr-[295px] sm:pt-[34px] sm:pl-[30px] sm:pr-[30px]">
      <div className="flex justify-between items-center sm:mb-[20px] sm:p-0 p-5">
        {elementChoisi === "movie" ? (
          <div className="flex">
            <div className="sm:hidden text-lg sm:text-base">
              Your <span className="text-[rgb(138,43,226)] text-lg">Movie</span>{" "}
              List :
            </div>
            <div className="text-[rgb(138,43,226)] hidden sm:block mr-[40px]">
              Movies
            </div>
            <div className="mr-[40px] hidden sm:block">Series</div>
          </div>
        ) : (
          <div className="flex">
            <div className="sm:hidden text-lg sm:text-base">
              Your <span className="text-[rgb(138,43,226)]">Serie</span> List :
            </div>
            <div className="mr-[40px] hidden sm:block">Movies</div>
            <div className="text-[rgb(138,43,226)] hidden sm:block mr-[40px]">
              Series
            </div>
          </div>
        )}
        <div className="flex">
          <img
            className="p-[7px] w-[32px] h-[32px] border-solid border rounded-[20px] invert-[.50] notification-pic"
            src={notification}
            alt=""
          />
          {currentUser ? (
            !isLoading && pdp !== "" ? (
              <div className="sm:ml-[20px] ml-2">
                <Link to="user">
                  <img
                    className="w-[32px] h-[32px] rounded-[20px] cursor-pointer"
                    src={pdp}
                    alt=""
                  />
                </Link>
              </div>
            ) : (
              <div className="sm:ml-[20px] ml-2">
                <Link to="user">
                  <div className="w-[32px] h-[32px] rounded-[20px] cursor-pointer bg-[rgb(36,36,36)]"></div>
                </Link>
              </div>
            )
          ) : (
            <div className="sm:ml-[20px] ml-2">
              <Link to="user">
                <img
                  className="w-[32px] h-[32px] rounded-[20px] cursor-pointer"
                  src={profilepic}
                  alt=""
                />
              </Link>
            </div>
          )}
        </div>
      </div>
      <div>
        <BookmarkedList />
      </div>
    </div>
  );
};

export default Bookmarked;
