import React from "react";
import { useState, useEffect, useRef } from "react";
import { Route, Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { db } from "../firebase.js";
import { addDoc, collection, doc, getDoc } from "firebase/firestore";

const PrivateRoute = ({ allowedRoles }) => {
  const { currentUser } = useAuth();
  const location = useLocation();
  const [role, setRole] = useState("");

  // function isanimeeditor() {
  //   if (currentUser) {
  //     const docRef = doc(db, "users", currentUser.uid);
  //     let heyy = "";

  //     return (heyy = getDoc(docRef).then((result) => {
  //       console.log(result.data().roles);
  //       const docSnap = result.data().roles;
  //       setRole(docSnap);
  //       return docSnap;
  //     }));
  //   }

  // }

  useEffect(() => {
    console.log("component re up");
    (async () => {
      if (currentUser) {
        const docRef = doc(db, "users", currentUser.uid);

        const docSnap = await getDoc(docRef);

        const userrole = docSnap.data().roles;

        setRole(userrole);
        console.log(userrole);
      }
    })();
  }, [role]);

  // console.log("isanimeeditor retourne :", role);
  // console.log(allowedRoles === "animeeditor");
  return currentUser && allowedRoles === "" ? (
    <Outlet />
  ) : currentUser && allowedRoles === role ? (
    <Outlet />
  ) : currentUser && allowedRoles !== role ? (
    <Navigate to="/Unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default PrivateRoute;
