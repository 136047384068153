import Signup from "./Signup";
import "./index.css";
import { AuthProvider } from "../contexts/AuthContext";
import Dashboard from "./Dashboard";
import Login from "./Login";
import PrivateRoute from "./PrivateRoute";
import LandingPage from "../pages/LandingPage";
import ForgotPassword from "./ForgotPassword";
import UpdateProfile from "./UpdateProfile";
import UpdatePassword from "./UpdatePassword";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import AddAnime from "./AddAnime";
import Unauthorized from "./Unauthorized";
import AnimePage from "./AnimePage";
import AnimeSelected from "./AnimeSelected";
import BookmarkedPage from "../pages/BookmarkedPage";
import { ElementChoisiProvider } from "./contexts/ContentContext";
import UserDashboard from "./UserDashboard";
import Search from "./Search";

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <ElementChoisiProvider>
          <Routes>
            {/* <Route path="/" element={<Layout />} /> */}
            {/* public routes */}

            <Route path="/" element={<LandingPage />} />
            <Route path="/anime/:id" element={<AnimePage />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/search" element={<Search />} />

            {/* we want to protect these routes */}
            <Route element={<PrivateRoute allowedRoles={""} />}>
              <Route path="/me" element={<Dashboard />} />
              <Route path="/user" element={<UserDashboard />} />

              <Route path="/bookmarked" element={<BookmarkedPage />} />
              <Route path="/update-profile" element={<UpdateProfile />} />
              <Route path="/update-password" element={<UpdatePassword />} />
              <Route path="/anime-selected" element={<AnimeSelected />} />
            </Route>
            <Route path="/Unauthorized" element={<Unauthorized />} />
            <Route element={<PrivateRoute allowedRoles={"animeeditor"} />}>
              <Route path="/add-anime" element={<AddAnime />} />
            </Route>

            {/* catch all */}
            <Route path="*" element={<Unauthorized />} />
          </Routes>
        </ElementChoisiProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
