import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import "../styles/dashboard.css";
import DashboardBtn from "./DashboardBtn";
import Header from "./Header";
import { db } from "../firebase.js";
import {
  addDoc,
  updateDoc,
  collection,
  doc,
  setDoc,
  getDoc,
  getDocs,
  query,
  arrayUnion,
  onSnapshot,
  arrayRemove,
  documentId,
  where,
} from "firebase/firestore";

export default function AnimeSelected() {
  const [error, setError] = useState("");
  const { currentUser, logout } = useAuth();
  const [allAnimeWatched, setAllAnimeWatched] = useState([]);
  const [allAnimeWatchedinfos, setAllAnimeWatchedinfos] = useState([]);
  const [animeDeleted, setAnimeDeleted] = useState(null);
  const navigate = useNavigate();
  var logocrop = require("../images/logocrop.png");

  const deleteFromWatched = (id) => {
    const docRef = doc(db, "users", currentUser.uid);
    setDoc(docRef, { watchedAnime: arrayRemove(id) }, { merge: true });
    // const docSnap = await getDoc(docRef);
    console.log("Anime Removed from liste !");
    // if (docSnap.data().watchedAnime.length === 0) {
    //   console.log("heyyyy je suis la ");
    //   navigate("/anime-selected");
    // }
  };

  useEffect(() => {
    console.log("heyyy");

    (async () => {
      console.log("JE SUIS LA AUSSI");
      //   const docRef = doc(db, "users", currentUser.uid);
      //   const animeWatched = await getDoc(docRef);
      //   console.log(animeWatched.data().watchedAnime);
      //   //   setAllAnimeWatched(animeWatched.data().watchedAnime);
      //   const q = query(
      //     collection(db, "anime"),
      //     where(documentId(), "in", animeWatched.data().watchedAnime)
      //   );
      //   const productsDocsSnap = await getDocs(q);

      //   const docs = productsDocsSnap.docs.map((doc) => {
      //     const data = doc.data();
      //     data.id = doc.id;
      //     return data;
      //   });
      //   console.log(docs);
      //   setAllAnimeWatched(docs);

      //   const unsub = onSnapshot(
      //     doc(db, "users", currentUser.uid),
      //     async (doc) => {
      //       console.log("Current data: ", doc.data().watchedAnime);
      //       setAllAnimeWatchedinfos(doc.data().watchedAnime);
      //       if (doc.data().watchedAnime.length === 0) {
      //         console.log("Liste Empty :!");
      //       } else {
      //         const q = query(
      //           collection(db, "anime"),
      //           where(documentId(), "in", doc.data().watchedAnime)
      //         );
      //         const productsDocsSnap = await getDocs(q);

      //         const docs = productsDocsSnap.docs.map((doc) => {
      //           const data = doc.data();
      //           data.id = doc.id;
      //           return data;
      //         });
      //         console.log(docs);
      //         setAllAnimeWatched(docs);
      //       }
      //     }
      //   );
    })();

    (async () => {
      //   const docRef = doc(db, "users", currentUser.uid);

      //   const docSnap = await getDoc(docRef);
      //   console.log("ALL ANIME WATCHED", docSnap.data().watchedAnime);

      //   setAllAnimeWatched(docSnap.data().watchedAnime);
      const unsub = onSnapshot(doc(db, "users", currentUser.uid), (doc) => {
        console.log("Current data: ", doc.data().watchedAnime);
        setAllAnimeWatched(doc.data().watchedAnime);
      });
      //   console.log(docs);
    })();

    (async () => {
      console.log("JE SUIS LA AVANTTTT");
    })();

    if (animeDeleted) {
      console.log("yooop");
      const docReftodelete = doc(db, "users", currentUser.uid);
      const resultat = allAnimeWatched.find(
        (anime) => anime.id === animeDeleted.id
      );
      console.log(resultat);
      setDoc(
        docReftodelete,
        { watchedAnime: arrayRemove(animeDeleted) },
        { merge: true }
      );
    }

    return () => {
      console.log("hop on demonte le component");
      //   setAllAnimeWatched(null);
    };
  }, []);

  return (
    <>
      <Header />
      <div className="dashboard-container">
        <div className="dashboard-grid">
          <DashboardBtn />
          <div className="profile-card">
            <div className="profile-content">
              <div>
                <div className="text-2xl text-white font-medium text-center">
                  My Anime Selection :
                </div>
                {error && (
                  <div className="">
                    <div
                      className="p-2 bg-indigo-800 items-center text-indigo-100 leading-none rounded-full flex mb-3"
                      role="alert"
                    >
                      <span className="flex rounded-full bg-indigo-500 uppercase px-2 py-1 text-xs font-bold mr-3">
                        ERROR
                      </span>
                      <span className="font-semibold mr-2 text-left flex-auto">
                        {error}
                      </span>
                      <svg
                        className="fill-current opacity-75 h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z" />
                      </svg>
                    </div>
                  </div>
                )}
              </div>
              <div>
                <strong className=" text-white">
                  {/* Email: {currentUser.uid} */}
                  {allAnimeWatched.length !== 0 ? (
                    allAnimeWatched.map((item, index) => (
                      <div className="anime-listed" key={index}>
                        <div className="anime-img-listed-container">
                          <img className="anime-img-listed" src={item.bgpics} />
                        </div>

                        <div className="anime-infos-listed">
                          <div className="anime-title-listed">
                            {item.title}
                            {item.id}
                          </div>
                          <button
                            onClick={() => {
                              deleteFromWatched(item);
                              //   setAnimeDeleted(item);
                            }}
                            className="delete-btn"
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    ))
                  ) : (
                    <h1>Tu n'a sauvegardé aucun anime !</h1>
                  )}
                  {/* {console.log(currentUser)} */}
                </strong>{" "}
                {/* {allAnimeWatched.map((item, index) => (
                  <div className="anime-listed" key={index}>
                    <div className="anime-img-listed-container">
                      <img className="anime-img-listed" src={item.bgpics} />
                    </div>

                    <div className="anime-infos-listed">
                      <div className="anime-title-listed">
                        {item.title}
                        {item.id}
                      </div>
                      <button
                        onClick={() => {
                          
                          setAnimeDeleted(item.id);
                        }}
                        className="delete-btn"
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                ))} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
