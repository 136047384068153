import { React, useState, useEffect, useRef } from "react";
import { storage } from "../firebase.js";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { v4 } from "uuid";
import { db } from "../firebase.js";
import {
  addDoc,
  collection,
  doc,
  setDoc,
  getDoc,
  getDocs,
  query,
  deleteDoc,
  updateDoc,
  onSnapshot,
  arrayUnion,
  deleteField,
} from "firebase/firestore";
import "firebase/storage";
import { useAuth } from "../contexts/AuthContext.js";

const PictureModal = ({ setModalOn, setChoice }) => {
  const [imageUpload, setImageUpload] = useState(null);
  const [animeDeleted, setAnimeDeleted] = useState(null);
  const [animepicDeleted, setAnimepicDeleted] = useState(null);
  const [fileDataURL, setFileDataURL] = useState(null);
  const [allDocs, setAllDocs] = useState([]);
  const [error, setError] = useState("");
  const { currentUser } = useAuth();
  const [isMounted, setIsMounted] = useState(true);
  const [pdp, setPdp] = useState([]);

  const handleCancelClick = () => {
    setChoice(false);
    setModalOn(false);
  };
  const docRef = doc(db, "users", currentUser.uid);
  const uploadImage = () => {
    if (imageUpload == null) return;
    // const fullnameuplad = imageUpload.name + v4();
    const imageRef = ref(storage, `userpictures/${imageUpload.name + v4()}`);
    uploadBytes(imageRef, imageUpload).then(() => {
      // alert("image uploaded");
      getDownloadURL(imageRef).then((url) => {
        // Insert url into an <img> tag to "download"
        // WE CAN PUT NOW THIS URL TO A ANIME IN FIREBASE

        const data = {
          pdp: deleteField(),
        };
        // addDoc(docRef, data).then((docRef) => {
        //   console.log("Document has been added successfully");
        // });
        if (pdp !== undefined) {
          deleteImage();
          updateDoc(docRef, data)
            .then(() => {
              console.log("Code Field has been deleted successfully");
              setDoc(docRef, { pdp: arrayUnion(url) }, { merge: true });
            })
            .catch(() => {
              console.log(error);
            });
        } else {
          console.log("Code Field has been deleted successfully");
          setDoc(docRef, { pdp: arrayUnion(url) }, { merge: true });
        }

        console.log(url);
        console.log("Document has been added successfully");
        setError("");
        handleCancelClick();
        handleCancel(); // J'appel cette fonction pour vider les inputs
      });
    });
  };
  const deleteImage = () => {
    // THIS DELETE THE IMAGE FROM FIREBASE STORAGE
    const fileRef = ref(storage, pdp);
    deleteObject(fileRef).then(() => {
      // File deleted successfully
      console.log("File Deleted");
    });
  };
  const handleCancel = () => {
    setFileDataURL(null);
    setImageUpload(null);
  };
  useEffect(() => {
    // console.log("component re up");
    // (async () => {
    //   const colRef = collection(db, "anime");

    //   const snapshots = await getDocs(colRef);

    //   const docs = snapshots.docs.map((doc) => {
    //     const data = doc.data();
    //     data.id = doc.id;
    //     return data;
    //   });
    //   setAllDocs(docs);
    //   console.log(docs);
    // })();

    // if (animeDeleted) {
    //   const docReftodelete = doc(db, "anime", animeDeleted);
    //   // const fileRef = storage.refFromURL(animepicDeleted);
    //   deleteImage();
    //   deleteDoc(docReftodelete).then(() => {
    //     // fileRef.delete().then(() => {
    //     //   // File deleted successfully
    //     //   console.log("File Deleted");
    //     // });
    //     console.log("Entire Document has been deleted successfully.");
    //   });
    // }

    let fileReader,
      isCancel = false;
    if (imageUpload) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setFileDataURL(result);
        }
      };
      fileReader.readAsDataURL(imageUpload);
    }
    if (currentUser) {
      const unsub = onSnapshot(doc(db, "users", currentUser.uid), (doc) => {
        if (isMounted) {
          // console.log("Current data: ", doc.data().watchedAnime);
          if (doc.data().pdp !== undefined) {
            console.log("Current profile picture: ", doc.data().pdp);
            setPdp(doc.data().pdp);
          } else {
            setPdp(undefined);
          }
        }
      });

      return () => {
        unsub(); // Unsubscribe from the snapshot listener when the component unmounts
      };
    }
    return () => {
      isCancel = true;
      setAnimeDeleted(null);
      setAnimepicDeleted(null);
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [imageUpload, animeDeleted, animepicDeleted]);
  return (
    <div className="bg-black bg-opacity-75 fixed inset-0 z-50 flex justify-center items-center">
      <div className="flex bg-[#2d2d30] w-[400px] p-[15px] rounded-xl flex-col justify-between">
        <div className="flex justify-between">
          <div className="flex text-white">Sélectionner une image</div>
          <div
            className="cursor-pointer"
            onClick={() => {
              handleCancelClick();
            }}
          >
            X
          </div>
        </div>
        {fileDataURL ? (
          <div className="flex justify-between ">
            {
              <img
                className="w-[100px] h-[100px] object-cover rounded-full my-3"
                src={fileDataURL}
                alt="preview"
              />
            }
            <button onClick={uploadImage} className="button-upload-image">
              Upload Image
            </button>
          </div>
        ) : null}
        <div className="flex items-center justify-center w-full">
          <label
            for="dropzone-file"
            className="flex flex-col items-center justify-center w-full h-[150px] border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
          >
            <input
              id="dropzone-file"
              type="file"
              className="hidden"
              onChange={(event) => setImageUpload(event.target.files[0])}
            />
          </label>
        </div>
      </div>
    </div>
  );
};

export default PictureModal;
