import { useState, useEffect } from "react";
import {
  logouticon,
  logo2,
  homeicon,
  movieicn,
  bookmarkedicn,
  loginicn,
  seriesicn,
  library,
  search,
} from "../assets";
import "../styles/Navbar.css";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import React, { useContext } from "react";
import { ElementChoisiContext } from "./contexts/ContentContext";
const Navbar = () => {
  const { currentUser, logout } = useAuth();
  const [error, setError] = useState("");
  const [active, setActive] = useState("");
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);

  const { elementChoisi, setElementChoisi } = useContext(ElementChoisiContext);

  const handleElementChoisi = (element) => {
    setElementChoisi(element);
  };

  async function handleLogout() {
    setError("");

    try {
      await logout();
      navigate("/login");
    } catch {
      setError("Failed to logout");
    }
  }
  useEffect(() => {
    const currentPath = window.location.pathname;
    if (currentPath === "/bookmarked") {
      setActive("bookmarked");
    } else {
      setActive("");
    }
  }, []);
  return (
    <div>
      <div className="fixed flex z-40 justify-between sm:block right-0 sm:top-0 bottom-0 left-0 w-screen sm:w-[230px] pt-[30px] pb-[20px] pl-[35px] pr-[35px] sm:border-r sm:border-t-0 border-t sm:border-[rgb(83,83,83)] border-[rgb(83,83,83)] select-none bg-black">
        <div className="hidden sm:flex items-center">
          <img
            className="w-[50px] h-[50px] mr-[11px] ml-[-3px]"
            src={logo2}
            alt="logo"
          />
          <p className="font-bold">OTAKUPASS</p>
        </div>
        <div className="sm:mt-[40px]">
          <div className="hidden sm:block font-bold sm:mt-[20px]">MENU</div>
          {active === "" ? (
            <Link to="/">
              <div
                className="sm:flex sm:flex-row flex-col items-center sm:mt-[20px] cursor-pointer"
                onClick={() => setActive("")}
              >
                <div className=" flex justify-center">
                  <img
                    className="menu-icon-active w-[20px] h-[20px] sm:mr-[15px]"
                    src={homeicon}
                    alt=""
                  />
                </div>
                <div className="text-[rgb(138,43,226)] flex items-center">
                  Home
                </div>
                <div className="hidden sm:block h-[17px] w-[7px] bg-[rgb(138,43,226)] absolute right-[-4px] outline outline-[6px] outline-black rounded-[10px] opacity-100"></div>
              </div>
            </Link>
          ) : (
            <Link to="/">
              <div
                className="sm:flex sm:flex-row flex-col sm:mt-[20px] cursor-pointer"
                onClick={() => setActive("")}
              >
                <div className=" flex justify-center">
                  <img
                    className="w-[20px] h-[20px] sm:mr-[15px] menu-icon"
                    src={homeicon}
                    alt=""
                  />
                </div>
                <div className="menu-subtitle flex items-center">Home</div>
                <div className="hidden h-[17px] w-[7px] bg-[rgb(138,43,226)] absolute right-[-4px] outline outline-[6px] outline-black rounded-[10px] opacity-100"></div>
              </div>
            </Link>
          )}
        </div>
        <div className="sm:hidden">
          <div className="hidden sm:block font-bold sm:mt-[20px]">MENU</div>
          {toggle ? (
            <div className="sm:flex sm:flex-row flex-col items-center sm:mt-[20px]">
              <div className=" flex justify-center">
                <div className="absolute flex item-center justify-center bottom-[100px] p-2 bg-[rgb(14,13,13)] rounded-sm border-[1px] border-[rgb(83,83,83)]">
                  <div className="flex sm:block sm:mt-[40px]">
                    <div
                      className="flex-col items-center sm:mt-[20px] cursor-pointer mr-4"
                      onClick={() => handleElementChoisi("movie")}
                    >
                      <div className="flex justify-center">
                        <img
                          className="w-[20px] h-[20px] sm:mr-[15px] menu-icon"
                          src={movieicn}
                          alt=""
                        />
                      </div>
                      <div className="menu-subtitle">Movies</div>
                      <div className="petite-icon-highlights"></div>
                    </div>
                    <div
                      className="flex-col items-center sm:mt-[20px] cursor-pointer"
                      onClick={() => handleElementChoisi("serie")}
                    >
                      <div className="flex justify-center">
                        <img
                          className="w-[20px] h-[20px] sm:mr-[15px] menu-icon"
                          src={seriesicn}
                          alt=""
                        />
                      </div>
                      <div className="menu-subtitle">Series</div>
                      <div className="petite-icon-highlights"></div>
                    </div>
                  </div>
                </div>
                <img
                  className="menu-icon-active w-[20px] h-[20px] sm:mr-[15px]"
                  src={library}
                  alt=""
                />
              </div>
              <div
                className="text-[rgb(138,43,226)] flex items-center"
                onClick={() => setToggle(false)}
              >
                Library
              </div>
              <div className="hidden sm:block h-[17px] w-[7px] bg-[rgb(138,43,226)] absolute right-[-4px] outline outline-[6px] outline-black rounded-[10px] opacity-100"></div>
            </div>
          ) : (
            <div
              className="sm:flex sm:flex-row flex-col sm:mt-[20px] cursor-pointer"
              onClick={() => setToggle(true)}
            >
              <div className=" flex justify-center">
                <img
                  className="w-[20px] h-[20px] sm:mr-[15px] menu-icon"
                  src={library}
                  alt=""
                />
              </div>
              <div className="menu-subtitle flex items-center">Library</div>
              <div className="hidden sm:block h-[17px] w-[7px] bg-[rgb(138,43,226)] absolute right-[-4px] outline outline-[6px] outline-black rounded-[10px] opacity-100"></div>
            </div>
          )}
        </div>
        <div className="hidden sm:block sm:mt-[40px]">
          <div className="hidden sm:block font-bold sm:mt-[20px]">CATEGORY</div>
          <div
            className="flex items-center sm:mt-[20px] cursor-pointer"
            onClick={() => handleElementChoisi("movie")}
          >
            <img
              className="w-[20px] h-[20px] sm:mr-[15px] menu-icon"
              src={movieicn}
              alt=""
            />
            <div className="menu-subtitle">Movies</div>
            <div className="petite-icon-highlights"></div>
          </div>
          <div
            className="flex items-center sm:mt-[20px] cursor-pointer"
            onClick={() => handleElementChoisi("serie")}
          >
            <img
              className="w-[20px] h-[20px] sm:mr-[15px] menu-icon"
              src={seriesicn}
              alt=""
            />
            <div className="menu-subtitle">Series</div>
            <div className="petite-icon-highlights"></div>
          </div>
        </div>
        <div className="sm:mt-[40px]">
          <div className="hidden sm:block font-bold sm:mt-[20px]">LIBRARY</div>
          {active === "bookmarked" ? (
            <Link to="/bookmarked">
              <div className="sm:flex sm:flex-row flex-col items-center sm:mt-[20px] cursor-pointer">
                <div className=" flex justify-center">
                  <img
                    className="menu-icon-active w-[20px] h-[20px] sm:mr-[15px]"
                    src={bookmarkedicn}
                    alt=""
                  />
                </div>
                <div className="text-[rgb(138,43,226)]">Bookmarked</div>
                <div className="hidden sm:block h-[17px] w-[7px] bg-[rgb(138,43,226)] absolute right-[-4px] outline outline-[6px] outline-black rounded-[10px] opacity-100"></div>
              </div>
            </Link>
          ) : (
            <Link to="/bookmarked">
              <div className="sm:flex sm:flex-row flex-col items-center sm:mt-[20px] cursor-pointer">
                <div className=" flex justify-center">
                  <img
                    className="w-[20px] h-[20px] sm:mr-[15px] menu-icon"
                    src={bookmarkedicn}
                    alt=""
                  />
                </div>
                <div className="menu-subtitle">Bookmarked</div>
                <div className="petite-icon-highlights"></div>
              </div>
            </Link>
          )}
        </div>
        <div className="hidden sm:block sm:mt-[40px]">
          <div className="hidden sm:block font-bold sm:mt-[20px]">GENERAL</div>
          {currentUser ? (
            <div className="sm:flex sm:flex-row flex-col items-center sm:mt-[20px] cursor-pointer">
              <div className="flex justify-center">
                <img
                  className="w-[20px] h-[20px] sm:mr-[15px] menu-icon"
                  src={logouticon}
                  onClick={handleLogout}
                  alt=""
                />
              </div>
              <button className="menu-subtitle" onClick={handleLogout}>
                Log Out
              </button>
              <div className="petite-icon-active"></div>
            </div>
          ) : (
            <Link to="/login">
              <div className="sm:flex sm:flex-row flex-col items-center sm:mt-[20px] cursor-pointer">
                <div className="flex justify-center">
                  <img
                    className="w-[20px] h-[20px] sm:mr-[15px] menu-icon"
                    src={loginicn}
                    alt=""
                  />
                </div>
                <div className="menu-subtitle">Log In</div>
              </div>
            </Link>
          )}
        </div>
        <div className="sm:hidden sm:mt-[40px]">
          <Link to="/search">
            <div className="sm:flex sm:flex-row flex-col items-center sm:mt-[20px] cursor-pointer">
              <div className="flex justify-center">
                <img
                  className="w-[20px] h-[20px] sm:mr-[15px] menu-icon"
                  src={search}
                  alt=""
                />
              </div>
              <button className="menu-subtitle">Search</button>
              <div className="petite-icon-active"></div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
