import React, { useState, useEffect, useContext } from "react";
import "../styles/MainContent.css";
import { flechedroite, flechegauche, star } from "../assets";
import axios from "axios";
import { ElementChoisiContext } from "./contexts/ContentContext";
import { useNavigate, generatePath } from "react-router-dom";

const Carousel = () => {
  const [movieData, setMovieData] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const { elementChoisi } = useContext(ElementChoisiContext);
  const navigate = useNavigate();
  const handleProceed = (id) => {
    id && navigate(generatePath("/anime/:id", { id }));
  };
  async function getTrendingMovieData(content) {
    try {
      const apiKey = "0de11815b0fb35bfb6cffac32f3b1207";

      let resp = await axios.get(
        `https://api.themoviedb.org/3/discover/${content}?api_key=${apiKey}&language=fr-EU&sort_by=popularity.desc&include_adult=false&include_video=false&page=1&with_genres=16&with_keywords=210024|287501`
      );
      // console.log(20, resp.data.results.slice(0, 7));

      const data = resp.data.results.slice(0, 7);

      // Récupérer les informations sur les épisodes pour chaque élément
      const promises = data.map(async (item) => {
        // console.log(item.id);
        let resp = await axios.get(
          `https://api.themoviedb.org/3/${content}/${item.id}?api_key=${apiKey}&language=fr-EU`
        );
        // console.log({
        //   number_of_seasons: resp.data.number_of_seasons,
        //   number_of_episodes: resp.data.number_of_episodes,
        // });

        return {
          ...item,
          number_of_seasons: resp.data.number_of_seasons,
          number_of_episodes: resp.data.number_of_episodes,
        };
      });

      // Attendre que toutes les promesses soient résolues et mettre à jour le state "movieData"
      const updatedData = await Promise.all(promises);
      setMovieData(updatedData);
    } catch (e) {
      console.error(e);
    }
  }

  const previousImage = () => {
    setCurrentImageIndex(
      currentImageIndex === 0 ? movieData.length - 1 : currentImageIndex - 1
    );
  };

  const nextImage = () => {
    setCurrentImageIndex(
      currentImageIndex === movieData.length - 1 ? 0 : currentImageIndex + 1
    );
  };
  const carouselInfiniteScroll = () => {
    if (currentImageIndex === movieData.length - 1) {
      return setCurrentImageIndex(0);
    }
    return setCurrentImageIndex(currentImageIndex + 1);
  };
  useEffect(() => {
    {
      elementChoisi === "movie"
        ? getTrendingMovieData("movie")
        : getTrendingMovieData("tv");
    }

    // getSaisonetepisode();
    const interval = setInterval(() => {
      // console.log("YOOOOOOOO");
      carouselInfiniteScroll();
    }, 10000); // 5000 ms = 5 sec
    return () => {
      clearInterval(interval);
      console.log("hop on demonte le component");
    };
  }, [currentImageIndex, elementChoisi]);

  return (
    <div className="relative sm:h-[400px] h-[200px] pl-[20px] pr-[20px] sm:p-0">
      {movieData.length > 0 ? (
        <>
          <img
            className="w-full h-full object-cover object-center rounded-[19px]"
            src={`https://image.tmdb.org/t/p/original/${movieData[currentImageIndex].backdrop_path}`}
            alt=""
          />
          <div className="absolute top-0 left-0 w-full sm:h-[400px] h-[200px] pl-[20px] pr-[20px] sm:p-0 z-0 overlay "></div>

          <div className="absolute top-0 left-0 z-10 w-full sm:h-[400px] h-[200px]  sm:pt-[15px] pt-[15px] sm:pl-[30px] pl-[30px] sm:pr-[0px] pr-[12px] ">
            <div className="flex items-center justify-between">
              <div className="left-side-slide">
                <button
                  className="carousel-button previous-button"
                  onClick={previousImage}
                >
                  <img className="previous-btn" src={flechegauche} alt="" />
                </button>
                <button className="" onClick={nextImage}>
                  <img className="next-btn" src={flechedroite} alt="" />
                </button>
              </div>
              <div className="right-side-slide">
                <button className="test2">
                  {movieData[currentImageIndex].vote_average}{" "}
                  <img className="main-star-color" src={star} alt="" />
                </button>
              </div>
            </div>
            <div className=" sm:text-[30px] text-[18px] mt-[20px] sm:w-1/2 w-1/2 max-h-[50px] overflow-hidden text-ellipsis cursor-pointer">
              <div
                className="cursor-pointer"
                onClick={(event) => {
                  event.stopPropagation();
                  handleProceed(movieData[currentImageIndex].id);
                }}
              >
                {movieData[currentImageIndex].name
                  ? movieData[currentImageIndex].name
                  : movieData[currentImageIndex].title}
              </div>
            </div>
            {elementChoisi === "movie" ? (
              <div />
            ) : (
              <div className="anime-saisons-title">
                {movieData[currentImageIndex].number_of_seasons} Saisons /{" "}
                {movieData[currentImageIndex].number_of_episodes} Episodes
              </div>
            )}

            <div className="anime-resume-carousel hidden sm:block">
              {movieData[currentImageIndex].overview}
            </div>
          </div>
        </>
      ) : (
        <p>Chargement en cours...</p>
      )}
    </div>
  );
};

export default Carousel;
