import { React, useEffect, useState, useContext } from "react";
import { db } from "../firebase.js";
import { useAuth } from "../contexts/AuthContext.js";
import { iconSeen, iconSeenempty, star } from "../assets";
import axios from "axios";
import "../styles/ReleasedRecently.css";
import { ElementChoisiContext } from "./contexts/ContentContext";
import { useNavigate, generatePath } from "react-router-dom";

const ReleasedRecently = () => {
  const { currentUser } = useAuth();
  const { elementChoisi } = useContext(ElementChoisiContext);
  const [movieData, setMovieData] = useState([]);
  const navigate = useNavigate();
  const handleProceed = (id) => {
    id && navigate(generatePath("/anime/:id", { id }));
  };

  useEffect(() => {
    {
      elementChoisi === "movie"
        ? getTrendingMovieData("movie")
        : getTrendingMovieData("tv");
    }
    // getSaisonetepisode();
    return () => {
      console.log("hop on demonte le component");
    };
  }, [elementChoisi]);
  async function getTrendingMovieData(content) {
    try {
      const apiKey = "0de11815b0fb35bfb6cffac32f3b1207";
      let resp = await axios.get(
        `https://api.themoviedb.org/3/discover/${content}?api_key=${apiKey}&language=fr-EU&sort_by=first_air_date.desc&include_adult=false&include_video=false&page=1&with_genres=16&with_keywords=210024|287501`
      );
      console.log(20, resp.data.results.slice(0, 10));

      const data = resp.data.results.slice(0, 2);

      // Récupérer les informations sur les épisodes pour chaque élément
      const promises = data.map(async (item) => {
        // console.log(item.id);
        let resp = await axios.get(
          `https://api.themoviedb.org/3/${content}/${item.id}?api_key=${apiKey}&language=fr-EU`
        );
        // console.log({
        //   number_of_seasons: resp.data.number_of_seasons,
        //   number_of_episodes: resp.data.number_of_episodes,
        // });

        return {
          ...item,
          number_of_seasons: resp.data.number_of_seasons,
          number_of_episodes: resp.data.number_of_episodes,
        };
      });

      // Attendre que toutes les promesses soient résolues et mettre à jour le state "movieData"
      const updatedData = await Promise.all(promises);
      setMovieData(updatedData);
    } catch (e) {
      console.error(e);
    }
  }
  return (
    <div>
      <div className="movie-cards">
        {movieData.map((item, index) => (
          <div className="movie_item" key={index}>
            <div className="pic-container">
              <img
                onClick={() => handleProceed(item.id)}
                className="movie-picture cursor-pointer"
                src={`https://image.tmdb.org/t/p/original/${item.poster_path}`}
              />
            </div>
            <div className="movie-infos">
              {elementChoisi === "movie" ? (
                <div>
                  <div className="font-bold text-[13px] mb-[6px] mt-2">
                    {item.name ? item.name : item.title}
                  </div>
                </div>
              ) : (
                <div>
                  <div className="movie-name">
                    {item.name ? item.name : item.title}
                  </div>
                  <div className="saisonepisodes">
                    Saisons : {item.number_of_seasons}
                    <br />
                    Episodes : {item.number_of_episodes}
                  </div>
                </div>
              )}

              <div className="score-container">
                <div className="score">
                  {item.vote_average == 0 ? "NR" : item.vote_average}
                </div>
                <div>
                  <img className="star-color" src={star} alt="" />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReleasedRecently;
