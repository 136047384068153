import React, { useRef } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext.js";
import Header from "../components/Header";
import "../styles/update-profile.css";
import DashboardBtn from "./DashboardBtn.js";

const UpdateProfile = () => {
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const { currentUser, updateEmail, updatePassword } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  var logocrop = require("../images/logocrop.png");

  async function handleSubmit(e) {
    e.preventDefault();

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match");
    }

    const promises = [];
    setLoading(true);
    setError("");
    if (emailRef.current.value !== currentUser.email) {
      promises.push(updateEmail(emailRef.current.value));
    }
    if (emailRef.current.value) {
      promises.push(updatePassword(passwordRef.current.value));
    }

    Promise.all(promises)
      .then(() => {
        navigate("/");
      })
      .catch(() => {
        setError("Failed to update account");
      })
      .finally(() => {
        setLoading(false);
      });
    // try {
    //   //   await signup(emailRef.current.value, passwordRef.current.value);
    //   history.push("/");
    // } catch {
    //   setError("Failed to create an account");
    // }
    // setLoading(false);
  }

  return (
    <>
      <Header />
      <div className="update-profile-container">
        <div className="update-profile-grid">
          <DashboardBtn />
          <form className="update-profile-card" onSubmit={handleSubmit}>
            <h2 className="text-2xl text-white font-medium text-center">
              Update profile
            </h2>
            {error && (
              <div class="">
                <div
                  class="p-2 bg-indigo-800 items-center text-indigo-100 leading-none rounded-full flex mb-3"
                  role="alert"
                >
                  <span class="flex rounded-full bg-indigo-500 uppercase px-2 py-1 text-xs font-bold mr-3">
                    ERROR
                  </span>
                  <span class="font-semibold mr-2 text-left flex-auto">
                    {error}
                  </span>
                  <svg
                    class="fill-current opacity-75 h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z" />
                  </svg>
                </div>
              </div>
            )}
            <div>
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-white"
              >
                E-mail
              </label>
              <input
                name="email"
                type="email"
                ref={emailRef}
                required
                defaultValue={currentUser.email}
                placeholder="Email"
                className=" bg-neutral-700 border border-neutral-600 text-white text-sm rounded-3xl block w-full p-2.5 "
              />
            </div>
            <div>
              <label
                htmlFor="password"
                className="block mb-2 text-sm font-medium text-white"
              >
                Your password
              </label>
              <input
                type="password"
                ref={passwordRef}
                placeholder="Your password"
                className="bg-neutral-700 border border-neutral-600 text-white text-sm rounded-3xl block w-full p-2.5 "
              />
            </div>
            <div>
              <label className="block mb-2 text-sm font-medium text-white">
                Confirm Password
              </label>
              <input
                type="password"
                ref={passwordConfirmRef}
                placeholder="Confirm your password"
                className={
                  "bg-neutral-700 border border-neutral-600 text-white text-sm rounded-3xl block w-full p-2.5"
                }
              />
            </div>
            <button
              className={
                "w-full border border-white text-white bg-neutral-900 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-3xl text-sm px-5 py-2.5 text-center dark:bg-neutral-700 dark:hover:bg-neutral-900 dark:focus:ring-blue-800"
              }
              disabled={loading}
            >
              Update
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default UpdateProfile;
