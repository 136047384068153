import React, { useState, useEffect, useContext } from "react";
import "../styles/TopRatedAnimes.css";
import axios from "axios";
import { iconSeen, iconSeenempty, star } from "../assets";
import { ElementChoisiContext } from "./contexts/ContentContext";
import { useNavigate, generatePath } from "react-router-dom";

const TopRatedAnime = () => {
  const [movieData, setMovieData] = useState([]);
  const { elementChoisi } = useContext(ElementChoisiContext);
  const navigate = useNavigate();
  const handleProceed = (id) => {
    id && navigate(generatePath("/anime/:id", { id }));
  };
  async function getTrendingMovieData(content) {
    try {
      const apiKey = "0de11815b0fb35bfb6cffac32f3b1207";
      let resp = await axios.get(
        `https://api.themoviedb.org/3/discover/${content}?api_key=${apiKey}&language=fr-EU&vote_count.gte=500&sort_by=vote_average.desc&page=1&with_genres=16&with_keywords=210024|287501`
      );
      console.log(20, resp.data.results.slice(0, 5));

      const data = resp.data.results.slice(0, 6);

      // Récupérer les informations sur les épisodes pour chaque élément
      const promises = data.map(async (item) => {
        // console.log(item.id);
        let resp = await axios.get(
          `https://api.themoviedb.org/3/${content}/${item.id}?api_key=${apiKey}&language=fr-EU`
        );
        // console.log({
        //   number_of_seasons: resp.data.number_of_seasons,
        //   number_of_episodes: resp.data.number_of_episodes,
        // });

        return {
          ...item,
          number_of_seasons: resp.data.number_of_seasons,
          number_of_episodes: resp.data.number_of_episodes,
        };
      });

      // Attendre que toutes les promesses soient résolues et mettre à jour le state "movieData"
      const updatedData = await Promise.all(promises);
      setMovieData(updatedData);
    } catch (e) {
      console.error(e);
    }
  }
  useEffect(() => {
    {
      elementChoisi === "movie"
        ? getTrendingMovieData("movie")
        : getTrendingMovieData("tv");
    }
    // getSaisonetepisode();

    return () => {
      console.log("hop on demonte le component");
    };
  }, [elementChoisi]);
  return (
    <div>
      <div className="sm:mt-[50px] mb-[100px] sm:mb-[0px] mt-[25px] sm:pl-[50px] pl-[20px] sm:pr-[50px] pr-[20px]">
        <div className="font-bold sm:mb-[20px] mb-[20px] sm:ml-[20px] ml-[30px]">
          Top Rated {elementChoisi === "movie" ? "Movie" : "Anime"}
        </div>
        <div className="tra-movie-card ">
          {movieData.map((item, index) => (
            <div className="tra-movie-item" key={index}>
              <div
                className="tra-pic-container cursor-pointer"
                onClick={() => handleProceed(item.id)}
              >
                <img
                  className="tra-movie-picture"
                  src={`https://image.tmdb.org/t/p/original/${item.poster_path}`}
                />
              </div>
              <div className="tra-movie-infos">
                <div
                  className="tra-movie-name cursor-pointer"
                  onClick={() => handleProceed(item.id)}
                >
                  {item.name ? item.name : item.title}
                </div>

                {/* <div className="tra-saisonsepisodes">
                  Saisons : {item.number_of_seasons}
                  <br />
                  Episodes : {item.number_of_episodes}
                </div> */}
                <div className="tra-score">
                  <button className="test2">
                    {item.vote_average}{" "}
                    <img className="main-star-color" src={star} alt="" />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TopRatedAnime;
