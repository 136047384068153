import React from "react";
import Header from "./Header";
import DashboardBtn from "./DashboardBtn";
import { useState, useEffect, useRef } from "react";
import "../styles/AddAnime.css";
import { storage } from "../firebase.js";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { v4 } from "uuid";
import { db } from "../firebase.js";
import {
  addDoc,
  collection,
  doc,
  setDoc,
  getDoc,
  getDocs,
  query,
  deleteDoc,
  onSnapshot,
} from "firebase/firestore";
import "firebase/storage";

function AddAnime() {
  const [error, setError] = useState("");
  const [allDocs, setAllDocs] = useState([]);
  const [imageUpload, setImageUpload] = useState(null);
  const [animeDeleted, setAnimeDeleted] = useState(null);
  const [animepicDeleted, setAnimepicDeleted] = useState(null);

  const [fileDataURL, setFileDataURL] = useState(null);
  const [imageList, setImageList] = useState([]);
  const animeTitleRef = useRef();

  const dbRef = collection(db, "anime");

  const uploadImage = () => {
    if (imageUpload == null) return;
    if (animeTitleRef.current.value == "") {
      return setError("Title cannot be empty !");
    }
    // const fullnameuplad = imageUpload.name + v4();
    const imageRef = ref(storage, `animepics/${imageUpload.name + v4()}`);
    uploadBytes(imageRef, imageUpload).then(() => {
      // alert("image uploaded");
      getDownloadURL(imageRef).then((url) => {
        // Insert url into an <img> tag to "download"
        // WE CAN PUT NOW THIS URL TO A ANIME IN FIREBASE

        const data = {
          bgpics: url,
          title: animeTitleRef.current.value,
        };
        addDoc(dbRef, data).then((docRef) => {
          console.log("Document has been added successfully");
        });
        console.log(url);
        setError("");
        handleCancel(); // J'appel cette fonction pour vider les inputs
      });
    });
  };

  const deleteImage = () => {
    // THIS DELETE THE IMAGE FROM FIREBASE STORAGE
    const fileRef = ref(storage, animepicDeleted);
    deleteObject(fileRef).then(() => {
      // File deleted successfully
      console.log("File Deleted");
    });
  };

  // async function deleteImage(animeidtodelete) {
  //   const docReftodelete = doc(db, "anime", animeidtodelete);
  //   deleteDoc(docReftodelete).then(() => {
  //     console.log("Entire Document has been deleted successfully.");
  //   });
  // }

  const handleCancel = () => {
    setFileDataURL(null);
    setImageUpload(null);
    document.getElementById("uploadCaptureInputFile").value = "";
  };

  useEffect(() => {
    console.log("component re up");
    (async () => {
      const colRef = collection(db, "anime");

      const snapshots = await getDocs(colRef);

      const docs = snapshots.docs.map((doc) => {
        const data = doc.data();
        data.id = doc.id;
        return data;
      });
      setAllDocs(docs);
      console.log(docs);
    })();

    if (animeDeleted) {
      const docReftodelete = doc(db, "anime", animeDeleted);
      // const fileRef = storage.refFromURL(animepicDeleted);
      deleteImage();
      deleteDoc(docReftodelete).then(() => {
        // fileRef.delete().then(() => {
        //   // File deleted successfully
        //   console.log("File Deleted");
        // });
        console.log("Entire Document has been deleted successfully.");
      });
    }

    let fileReader,
      isCancel = false;
    if (imageUpload) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setFileDataURL(result);
        }
      };
      fileReader.readAsDataURL(imageUpload);
    }

    return () => {
      isCancel = true;
      setAnimeDeleted(null);
      setAnimepicDeleted(null);
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [imageUpload, animeDeleted, animepicDeleted]);

  return (
    <div>
      <Header />
      <div className="dashboard-container2">
        <div className="dashboard-grid">
          <DashboardBtn />
          <div className="profile-card">
            <div className="profile-content">
              <div>
                {error && (
                  <div className="">
                    <div
                      className="p-2 bg-indigo-800 items-center text-indigo-100 leading-none rounded-full flex mb-3"
                      role="alert"
                    >
                      <span className="flex rounded-full bg-indigo-500 uppercase px-2 py-1 text-xs font-bold mr-3">
                        ERROR
                      </span>
                      <span className="font-semibold mr-2 text-left flex-auto">
                        {error}
                      </span>
                      <svg
                        className="fill-current opacity-75 h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z" />
                      </svg>
                    </div>
                  </div>
                )}
                <div className="text-2xl text-white font-medium text-center">
                  Add a new anime
                </div>
                {/* <form> */}
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-white"
                >
                  Anime Title :
                </label>
                <input
                  ref={animeTitleRef}
                  required
                  placeholder="Enter the title of the anime"
                  className=" bg-neutral-700 border border-neutral-600 text-white text-sm rounded-3xl block p-2.5 "
                />
                <input
                  type="file"
                  required
                  accept="image/*"
                  id="uploadCaptureInputFile"
                  onChange={(event) => setImageUpload(event.target.files[0])}
                />
                <button onClick={handleCancel} className="button-upload-image">
                  Clear
                </button>
                <button onClick={uploadImage} className="button-upload-image">
                  Upload Image
                </button>
                {/* </form> */}
              </div>
              {fileDataURL ? (
                <div className="img-preview-wrapper">
                  {
                    <img
                      className="img-preview"
                      src={fileDataURL}
                      alt="preview"
                    />
                  }
                </div>
              ) : null}
              <div>
                {/* Email: {currentUser.uid} */}
                {/* {emailtest} */}
                {/* {console.log(currentUser)} */}
                <div className="text-2xl text-white font-medium text-center">
                  Anime liste :
                </div>
                {allDocs.map((item, index) => (
                  <div className="anime-listed" key={index}>
                    <div className="anime-img-listed-container">
                      <img className="anime-img-listed" src={item.bgpics} />
                    </div>
                    {item.id}
                    <div className="anime-infos-listed">
                      <div className="anime-title-listed">{item.title}</div>
                      <button
                        onClick={() => {
                          setAnimeDeleted(item.id);
                          setAnimepicDeleted(item.bgpics);
                        }}
                        className="delete-btn"
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddAnime;
