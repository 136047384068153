// Créez un contexte pour stocker l'élément choisi
import React, { createContext, useState, useEffect } from "react";

const ElementChoisiContext = createContext();

const ElementChoisiProvider = ({ children }) => {
  const storedValue = localStorage.getItem("elementChoisi");
  const [elementChoisi, setElementChoisi] = useState(storedValue || "movie");

  useEffect(() => {
    localStorage.setItem("elementChoisi", elementChoisi);
  }, [elementChoisi]);

  return (
    <ElementChoisiContext.Provider value={{ elementChoisi, setElementChoisi }}>
      {children}
    </ElementChoisiContext.Provider>
  );
};

export { ElementChoisiContext, ElementChoisiProvider };
