import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import "../styles/dashboard.css";
import DashboardBtn from "./DashboardBtn";
import Header from "./Header";

export default function Dashboard() {
  const [error, setError] = useState("");
  const { currentUser, logout } = useAuth();
  const [emailtest, setemailtest] = useState("");
  const navigate = useNavigate();
  var logocrop = require("../images/logocrop.png");

  async function handleLogout() {
    setError("");

    try {
      await logout();
      navigate.push("/login");
    } catch {
      setError("Failed to logout");
    }
  }
  useEffect(() => {
    console.log("heyyy");
    setemailtest(currentUser.email);
    return () => {
      console.log("hop on demonte le component");
      setemailtest(""); // je crois que ici c'est l'endroit pour reset le component à verifier
    };
  }, []);

  return (
    <>
      <Header />
      <div className="dashboard-container">
        <div className="dashboard-grid">
          <DashboardBtn />
          <div className="profile-card">
            <div className="profile-content">
              <div>
                <div className="text-2xl text-white font-medium text-center">
                  Profile
                </div>
                {error && (
                  <div className="">
                    <div
                      className="p-2 bg-indigo-800 items-center text-indigo-100 leading-none rounded-full flex mb-3"
                      role="alert"
                    >
                      <span className="flex rounded-full bg-indigo-500 uppercase px-2 py-1 text-xs font-bold mr-3">
                        ERROR
                      </span>
                      <span className="font-semibold mr-2 text-left flex-auto">
                        {error}
                      </span>
                      <svg
                        className="fill-current opacity-75 h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z" />
                      </svg>
                    </div>
                  </div>
                )}
              </div>
              <div>
                <strong className=" text-white">
                  {/* Email: {currentUser.uid} */}
                  Email: {emailtest}
                  {/* {console.log(currentUser)} */}
                </strong>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
