import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../components/header.css";

export default function Header() {
  var logocrop = require("../images/logocrop.png");
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (isOpen) {
      return document.body.classList.add("stop-scrolling");
    }
    return document.body.classList.remove("stop-scrolling");
  });
  return (
    <div>
      <nav className="header">
        <div className="header-container">
          <div className="header-container-second">
            <div className="header-container-third">
              <div className="header-logo-picture">
                <Link to="/">
                  <img className="logo-picture" src={logocrop} />
                </Link>
              </div>
              <div className="header-container-fourth">
                <div className="header-container-fifth">
                  <Link to="/me" className="header-buttons">
                    Dashboard
                  </Link>

                  <a href="#" className="header-buttons">
                    Team
                  </a>

                  <a href="#" className="header-buttons">
                    Projects
                  </a>

                  <a href="#" className="header-buttons">
                    Calendar
                  </a>

                  <a href="#" className="header-buttons">
                    Reports
                  </a>
                </div>
              </div>
            </div>
            <div className="header-hamburger-button-container">
              <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className="header-hamburger-button"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                {/* <span className="sr-only">Open main menu</span> */}
                {!isOpen ? (
                  <svg
                    className="svg-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                ) : (
                  <svg
                    className="svg-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>
        <div>
          {!isOpen ? (
            <div className="menuclosed"></div>
          ) : (
            <div className="menuopen"></div>
          )}
        </div>
      </nav>
    </div>
  );
}
