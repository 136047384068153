import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext.js";

const Login = () => {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { currentUser, login, LoginWithGoogle } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  var logocrop = require("../images/logocrop.png");
  var logogoogle = require("../images/Google__G__Logo.svg.png");

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setError("");
      setLoading(true);
      await login(emailRef.current.value, passwordRef.current.value);
      navigate(from, { replace: true });
    } catch {
      setError("Failed to log in");
    }
    setLoading(false);
  }
  async function handleSubmite(ed) {
    ed.preventDefault();
    try {
      setError("");
      setLoading(true);
      await LoginWithGoogle();
      // TODO VERIFICATION SI LA DB USERS EST CREER SUR FIRESTOR SINON LA CREER
      navigate("/me");
    } catch {
      setError("Failed to log in");
    }
    setLoading(false);
  }
  useEffect(() => {
    // console.log("heyyy");
    return () => {
      setLoading({}); // This worked for me
    };
  }, []);

  return (
    <div className="bg-neutral-900 w-screen h-screen flex justify-center items-center">
      <div className="p-4 xl:w-1/3 2xl:w-1/5 sm:w-1/3 lg:w-1/3 rounded-3xl border border-neutral-700 shadow-md sm:p-6 lg:p-8 bg-neutral-800">
        <form className="space-y-6" onSubmit={handleSubmit}>
          <div className="flex justify-center items-center">
            <Link to="/">
              <img src={logocrop} height={120} width={120} />
            </Link>
          </div>
          <h2 className="text-2xl text-white font-medium text-center">
            Log to your account
          </h2>
          {error && (
            <div className="">
              <div
                className="p-2 bg-indigo-800 items-center text-indigo-100 leading-none rounded-full flex mb-3"
                role="alert"
              >
                <span className="flex rounded-full bg-indigo-500 uppercase px-2 py-1 text-xs font-bold mr-3">
                  ERROR
                </span>
                <span className="font-semibold mr-2 text-left flex-auto">
                  {error}
                </span>
                <svg
                  className="fill-current opacity-75 h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z" />
                </svg>
              </div>
            </div>
          )}
          <div>
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-white"
            >
              Email
            </label>
            <input
              name="email"
              type="email"
              ref={emailRef}
              required
              placeholder="Email"
              className={
                "bg-neutral-700 border border-neutral-600 text-white text-sm rounded-3xl block w-full p-2.5"
              }
            />
          </div>
          <div>
            <label
              htmlFor="password"
              className="block mb-2 text-sm font-medium text-white"
            >
              Password:
            </label>
            <input
              type="password"
              ref={passwordRef}
              required
              placeholder="Password"
              className={
                "bg-neutral-700 border border-neutral-600 text-white text-sm rounded-3xl block w-full p-2.5"
              }
            />
          </div>

          <button
            className={
              "w-full border border-white text-white bg-neutral-900 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-3xl text-sm px-5 py-2.5 text-center dark:bg-neutral-700 dark:hover:bg-neutral-900 dark:focus:ring-blue-800"
            }
            // disabled={loading}
          >
            Log in
          </button>

          <div className="text-white text-center">
            <Link to="/signup">Need an account? Signup</Link>
          </div>
          <div className="text-white text-center">
            <Link to="/forgot-password">Forgot Password ?</Link>
          </div>
          <div className="relative flex items-center">
            <div className="flex-grow border-t border-neutral-500"></div>
            <span className="flex-shrink mx-4 text-neutral-500">Or</span>
            <div className="flex-grow border-t border-neutral-500"></div>
          </div>
        </form>
        <div className="flex justify-center items-center mt-2">
          {/* <button
            className={
              " border border-white text-white bg-neutral-900 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-3xl text-sm px-5 py-2.5 text-center dark:bg-neutral-700 dark:hover:bg-neutral-900 dark:focus:ring-blue-800"
            }
            disabled={loading}
            onClick={handleSubmite}
          >
            <img src={logogoogle} height={25} width={25} />
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default Login;
