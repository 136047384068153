import React, { useEffect, useState, useContext } from "react";
import { db } from "../firebase.js";
import { useAuth } from "../contexts/AuthContext.js";
import "../styles/SearchBar.css";
import { iconSeen, iconSeenempty, star } from "../assets";
import {
  addDoc,
  updateDoc,
  collection,
  doc,
  setDoc,
  getDoc,
  getDocs,
  query,
  arrayUnion,
  onSnapshot,
  arrayRemove,
  increment,
} from "firebase/firestore";
import { ElementChoisiContext } from "./contexts/ContentContext";
import { useNavigate, generatePath } from "react-router-dom";

const WatchList = () => {
  const [allAnimeWatched, setAllAnimeWatched] = useState([]);
  const { currentUser } = useAuth();
  const { elementChoisi } = useContext(ElementChoisiContext);
  const [isMounted, setIsMounted] = useState(true);
  const navigate = useNavigate();
  const handleProceed = (id) => {
    id && navigate(generatePath("/anime/:id", { id }));
  };

  const addToWatched = (id) => {
    if (currentUser) {
      const docRef = doc(db, "users", currentUser.uid);

      console.log("VOILAAA", id);
      const resultat = allAnimeWatched.find((anime) => anime.id === id.id);
      console.log("resultat", resultat);

      console.log("resultat", resultat);

      if (resultat !== undefined) {
        setDoc(docRef, { watchedAnime: arrayRemove(id) }, { merge: true });
      } else {
        setDoc(docRef, { watchedAnime: arrayUnion(id) }, { merge: true });
      }
    } else {
      navigate("/login");
    }

    console.log("Anime Saved !");
  };

  //   useEffect(() => {
  //     (async () => {
  //       console.log("JE SUIS LA dans watchlist");
  //       //   const docRef = doc(db, "users", currentUser.uid);
  //       //   const animeWatched = await getDoc(docRef);
  //       //   console.log(animeWatched.data().watchedAnime);
  //       //   setAllAnimeWatched(animeWatched.data().watchedAnime);

  //       if (currentUser) {
  //         const unsub = onSnapshot(doc(db, "users", currentUser.uid), (doc) => {
  //           if (isMounted) {
  //             // Vérifier si le composant est toujours monté avant de mettre à jour l'état
  //             console.log("Current data: ", doc.data().watchedAnime);
  //             setAllAnimeWatched(doc.data().watchedAnime);
  //           }
  //         });
  //       }
  //     })();
  //   }, [currentUser, isMounted]);
  useEffect(() => {
    console.log("JE SUIS LA dans watchlist");

    if (currentUser) {
      const unsub = onSnapshot(doc(db, "users", currentUser.uid), (doc) => {
        if (isMounted) {
          if (doc.data().watchedAnime) {
            console.log("Current data: ", doc.data().watchedAnime);
            console.log("Current data: ", doc.data().watchedAnime.slice(0, 2));
            setAllAnimeWatched(doc.data().watchedAnime.slice(0, 2));
          }
        }
      });

      return () => {
        unsub(); // Unsubscribe from the snapshot listener when the component unmounts
      };
    }

    // Return a no-op cleanup function if currentUser is not defined
    return () => {};
  }, [currentUser, isMounted]);
  return (
    <div>
      {allAnimeWatched.length === 0 ? (
        <div className="mb-6">
          Tu na aucun Anime ou <br /> Film marqué !<br /> Tu peux en ajouter via{" "}
          <br /> la barre de recherche ^^
        </div>
      ) : (
        allAnimeWatched.map((item, index) => (
          <div className="movie_item" key={index}>
            <div className="pic-container">
              <img
                onClick={() => handleProceed(item.id)}
                className="movie-picture cursor-pointer"
                src={`https://image.tmdb.org/t/p/original/${item.poster_path}`}
              />
            </div>

            <div className="movie-infos">
              <div className="movie-name">
                {item.name ? item.name : item.title}
              </div>

              <div className="saisonepisodes">
                Saisons : {item.number_of_seasons}
                <br />
                Episodes : {item.number_of_episodes}
              </div>
              <div className="score-container">
                <div className="score">{item.vote_average}</div>
                <div>
                  <img className="star-color" src={star} alt="" />
                </div>
                {currentUser &&
                allAnimeWatched.find((anime) => anime.id === item.id) !==
                  undefined ? (
                  <button
                    className="mark"
                    onClick={() => {
                      addToWatched(item);
                    }}
                  >
                    <img src={iconSeen} />
                  </button>
                ) : (
                  <button
                    className="mark"
                    onClick={() => {
                      addToWatched(item);
                    }}
                  >
                    <img src={iconSeenempty} />
                  </button>
                )}
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default WatchList;
