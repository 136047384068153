import React from "react";
import FirstPage from "../components/FirstPage";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import SecondPage from "../components/SecondPage";
import { useState, useEffect } from "react";
import "../styles/generals.css";
import SearchBar from "../components/SearchBar";
import MainContent from "../components/MainContent";

export default function LandingPage() {
  return (
    <div className="">
      <Navbar />
      <SearchBar />
      <MainContent />
      {/* <Header /> */}
      {/* <FirstPage topAnime={topAnime} />
      <SecondPage /> */}
    </div>
  );
}
