import { React, useState, useContext } from "react";
import "../styles/SearchBar.css";
import { logo, search, menupoints } from "../assets";
import List from "./List";
import PopularAnime from "./PopularAnime";
import { useAuth } from "../contexts/AuthContext.js";
import ReleasedRecently from "./ReleasedRecently";
import WatchList from "./WatchList";
import { Link } from "react-router-dom";
import { ElementChoisiContext } from "./contexts/ContentContext";

const SearchBar = () => {
  const { elementChoisi } = useContext(ElementChoisiContext);
  const { currentUser } = useAuth();
  const [inputText, setInputText] = useState("");
  let inputHandler = (e) => {
    //convert input text to lower case
    let lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
  };
  return (
    <div className="hidden sm:block">
      <div className="searchbar-section-container">
        <div className="searchbar-section">
          <div className="fixed top-[30px] flex items-center">
            <input
              className="search-bar"
              type="text"
              placeholder="Type to Search.."
              onChange={inputHandler}
            />
            <img
              className="absolute top-[10px] left-[10px] w-[20px] h-[20px] invert-[.35] items-center justify-between"
              src={search}
              alt=""
            />
          </div>
        </div>
        {inputText === "" ? (
          <div className="pr-[20px]">
            <div className="title-image-container">
              <div className="searchbar-title">
                Popular {elementChoisi === "movie" ? "Movies" : "Animes"}
              </div>
              <img className="menu-points" src={menupoints} alt="" />
            </div>
            <PopularAnime />
            <button className="seemore-btn">See More</button>
            {currentUser ? (
              <div>
                <div className="title-image-container">
                  <div className="searchbar-title">Watchlists</div>
                  <img className="menu-points" src={menupoints} alt="" />
                </div>
                <WatchList />
                <Link to="/bookmarked">
                  <button className="seemore-btn">See More</button>
                </Link>
              </div>
            ) : (
              <div>
                <div className="title-image-container">
                  <div className="searchbar-title">Released recently</div>
                  <img className="menu-points" src={menupoints} alt="" />
                </div>
                <ReleasedRecently />
                <button className="seemore-btn">See More</button>
              </div>
            )}
          </div>
        ) : (
          <List input={inputText} />
        )}
        {/* <List input={inputText} /> */}
      </div>
    </div>
  );
};

export default SearchBar;
