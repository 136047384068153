import React from "react";
import { useState, useEffect, useContext } from "react";
import { useNavigate, generatePath } from "react-router-dom";
import { db } from "../firebase.js";
import { useAuth } from "../contexts/AuthContext.js";
import { iconSeen, iconSeenempty, star } from "../assets";
import axios from "axios";
import { ElementChoisiContext } from "./contexts/ContentContext";
import {
  addDoc,
  updateDoc,
  collection,
  doc,
  setDoc,
  getDoc,
  getDocs,
  query,
  arrayUnion,
  onSnapshot,
  arrayRemove,
  increment,
} from "firebase/firestore";

const List = (props) => {
  const [allDocs, setAllDocs] = useState([]);
  const { currentUser } = useAuth();
  const [allAnimeWatched, setAllAnimeWatched] = useState([]);
  const [allMovieWatched, setAllMovieWatched] = useState([]);
  const [movieData, setMovieData] = useState([]);
  const navigate = useNavigate();
  const { elementChoisi } = useContext(ElementChoisiContext);

  const handleProceed = (id) => {
    id && navigate(generatePath("/anime/:id", { id }));
  };

  const addToWatched = (id) => {
    if (elementChoisi === "movie") {
      if (currentUser) {
        const docRef = doc(db, "users", currentUser.uid);
        let animeinfooos = id ?? "heyyy";

        if (allMovieWatched === undefined || allMovieWatched === []) {
          setDoc(docRef, { watchedMovie: arrayUnion(id) }, { merge: true });
        } else {
          console.log("VOILAAA", id);
          const resultat = allMovieWatched.find((anime) => anime.id === id.id);
          console.log("resultat", resultat);

          console.log("resultat", resultat);

          if (resultat !== undefined) {
            setDoc(docRef, { watchedMovie: arrayRemove(id) }, { merge: true });
          } else {
            console.log("C'est ici le probleme", animeinfooos);

            setDoc(docRef, { watchedMovie: arrayUnion(id) }, { merge: true });
          }
        }
      } else {
        navigate("/login");
      }
    } else {
      if (currentUser) {
        const docRef = doc(db, "users", currentUser.uid);
        let animeinfooos = id ?? "heyyy";

        if (allAnimeWatched === undefined || allAnimeWatched === []) {
          setDoc(docRef, { watchedAnime: arrayUnion(id) }, { merge: true });
        } else {
          console.log("VOILAAA", id);
          const resultat = allAnimeWatched.find((anime) => anime.id === id.id);
          console.log("resultat", resultat);

          console.log("resultat", resultat);

          if (resultat !== undefined) {
            setDoc(docRef, { watchedAnime: arrayRemove(id) }, { merge: true });
          } else {
            console.log("C'est ici le probleme", animeinfooos);

            setDoc(docRef, { watchedAnime: arrayUnion(id) }, { merge: true });
          }
        }
      } else {
        navigate("/login");
      }
    }

    console.log("Anime Saved !");
  };

  const filteredData = allDocs.filter((el) => {
    //if no input the return the original
    // if (props.input === "") {
    //   //TODO CREATE COMPONENT FOR POPULAR ANIME AND WATCHLIST
    //   return el;
    // }
    // //return the item which contains the user input
    // else {
    //   return el.title.toLowerCase().includes(props.input);
    // }
  });
  useEffect(() => {
    console.log("heyyy");
    async function getTrendingMovieData(content) {
      try {
        const apiKey = "0de11815b0fb35bfb6cffac32f3b1207";
        let resp = await axios.get(
          `https://api.themoviedb.org/3/discover/${content}?api_key=${apiKey}&language=fr-EU&page=1&with_genres=16&with_keywords=210024|287501&with_text_query=${props.input}&include_adult=false`
        );
        console.log(20, resp.data.results.slice(0, 10));

        const data = resp.data.results.slice(0, 10);

        if (content === "tv") {
          // Récupérer les informations sur les épisodes pour chaque élément
          const promises = data.map(async (item) => {
            // console.log(item.id);
            let resp = await axios.get(
              `https://api.themoviedb.org/3/${content}/${item.id}?api_key=${apiKey}&language=fr-EU`
            );
            // console.log({
            //   number_of_seasons: resp.data.number_of_seasons,
            //   number_of_episodes: resp.data.number_of_episodes,
            // });

            return {
              ...item,
              number_of_seasons: resp.data.number_of_seasons,
              number_of_episodes: resp.data.number_of_episodes,
            };
          });
          //Attendre que toutes les promesses soient résolues et mettre à jour le state "movieData"
          const updatedData = await Promise.all(promises);
          setMovieData(updatedData);
        } else {
          setMovieData(data);
        }
      } catch (e) {
        console.error(e);
      }
    }

    (async () => {
      //   console.log("JE SUIS LA AVANTTTT");
      const colRef = collection(db, "anime");

      const snapshots = await getDocs(colRef);

      const docs = snapshots.docs.map((doc) => {
        const data = doc.data();
        data.id = doc.id;
        return data;
      });
      setAllDocs(docs);
      //   console.log("ALL DOCS", docs);
    })();
    (async () => {
      console.log("JE SUIS LA AUSSI");
      // const docRef = doc(db, "users", currentUser.uid);
      // const animeWatched = await getDoc(docRef);
      // console.log(animeWatched.data().watchedAnime);
      // setAllAnimeWatched(animeWatched.data().watchedAnime);

      if (currentUser) {
        const unsub = onSnapshot(doc(db, "users", currentUser.uid), (doc) => {
          console.log("Current data: ", doc.data().watchedAnime);
          setAllAnimeWatched(doc.data().watchedAnime);
          setAllMovieWatched(doc.data().watchedMovie);
        });
      }
    })();
    {
      elementChoisi === "movie"
        ? getTrendingMovieData("movie")
        : getTrendingMovieData("tv");
    }

    return () => {
      console.log("hop on demonte le component");
    };
  }, [props, elementChoisi]);
  return (
    <div className="sm:m-0 mt-[80px] ml-[20px] mb-[120px]">
      {movieData.map((item, index) => (
        <div className="movie_item" key={index}>
          <div className="pic-container">
            <img
              onClick={() => handleProceed(item.id)}
              className="movie-picture cursor-pointer"
              src={`https://image.tmdb.org/t/p/original/${item.poster_path}`}
            />
          </div>

          <div className="movie-infos">
            {elementChoisi === "movie" ? (
              <div>
                <div className="font-bold text-[13px] mb-[5px]">
                  {item.name ? item.name : item.title}
                </div>
              </div>
            ) : (
              <div>
                <div className="movie-name">
                  {item.name ? item.name : item.original_name}
                </div>

                <div className="saisonepisodes">
                  Saisons : {item.number_of_seasons}
                  <br />
                  Episodes : {item.number_of_episodes}
                </div>
              </div>
            )}

            <div className="score-container">
              <div className="score">{item.vote_average}</div>
              <div>
                <img className="star-color" src={star} alt="" />
              </div>
              {elementChoisi === "movie" ? (
                allMovieWatched !== undefined ? (
                  currentUser &&
                  allMovieWatched.find((anime) => anime.id === item.id) !==
                    undefined ? (
                    <button
                      className="mark"
                      onClick={() => {
                        addToWatched(item);
                      }}
                    >
                      <img src={iconSeen} />
                    </button>
                  ) : (
                    <button
                      className="mark"
                      onClick={() => {
                        addToWatched(item);
                      }}
                    >
                      <img src={iconSeenempty} />
                    </button>
                  )
                ) : (
                  <button
                    className="mark"
                    onClick={() => {
                      addToWatched(item);
                    }}
                  >
                    <img src={iconSeenempty} />
                  </button>
                )
              ) : allAnimeWatched !== undefined ? (
                currentUser &&
                allAnimeWatched.find((anime) => anime.id === item.id) !==
                  undefined ? (
                  <button
                    className="mark"
                    onClick={() => {
                      addToWatched(item);
                    }}
                  >
                    <img src={iconSeen} />
                  </button>
                ) : (
                  <button
                    className="mark"
                    onClick={() => {
                      addToWatched(item);
                    }}
                  >
                    <img src={iconSeenempty} />
                  </button>
                )
              ) : (
                <button
                  className="mark"
                  onClick={() => {
                    addToWatched(item);
                  }}
                >
                  <img src={iconSeenempty} />
                </button>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default List;
