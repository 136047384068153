import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import "../styles/dashboard.css";
import { db } from "../firebase.js";
import { addDoc, collection, doc, getDoc } from "firebase/firestore";

export default function DashboardBtn() {
  const [error, setError] = useState("");
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  var logocrop = require("../images/logocrop.png");
  const [role, setRole] = useState("");

  async function handleLogout() {
    setError("");

    try {
      await logout();
      navigate("/login");
    } catch {
      setError("Failed to logout");
    }
  }
  useEffect(() => {
    console.log("component re up");
    (async () => {
      const docRef = doc(db, "users", currentUser.uid);

      const docSnap = await getDoc(docRef);

      const userrole = docSnap.data().roles;

      setRole(userrole);
      console.log(userrole);
    })();
  }, [role]);

  return (
    <div className="button-card">
      <div className=" button-update-profile">
        <Link to="/me">My Profile</Link>
      </div>
      <div className=" button-update-profile">
        <Link to="/anime-selected">My Anime selection</Link>
      </div>
      <div className=" button-update-profile">
        <Link to="/update-profile">Update profile</Link>
      </div>
      {role === "animeeditor" ? (
        <div className=" button-update-profile">
          <Link to="/add-anime">Add/Edit a New Anime</Link>
        </div>
      ) : (
        <div></div>
      )}

      <div className=" button-update-profile">
        <Link to="/update-password">Change Password</Link>
      </div>
      <button className="button-update-profile" onClick={handleLogout}>
        Log Out
      </button>
    </div>
  );
}
