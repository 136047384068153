import { React, useState, useEffect } from "react";
import Navbar from "./Navbar";
import SearchBar from "./SearchBar";
import { logo, profilepic, notification } from "../assets";
import PictureModal from "../modals/PictureModal";
import { useAuth } from "../contexts/AuthContext.js";
import {
  addDoc,
  updateDoc,
  collection,
  doc,
  setDoc,
  getDoc,
  getDocs,
  query,
  arrayUnion,
  onSnapshot,
  arrayRemove,
  increment,
} from "firebase/firestore";
import { db } from "../firebase.js";

const UserDashboard = () => {
  const [modalOn, setModalOn] = useState(false);
  const [choice, setChoice] = useState(false);
  const { currentUser } = useAuth();
  const [isMounted, setIsMounted] = useState(true);
  const [pdp, setPdp] = useState([]);

  const clicked = () => {
    setModalOn(true);
  };
  useEffect(() => {
    console.log("JE SUIS LA dans watchlist");

    if (currentUser) {
      const unsub = onSnapshot(doc(db, "users", currentUser.uid), (doc) => {
        if (isMounted) {
          // console.log("Current data: ", doc.data().watchedAnime);
          if (doc.data().pdp !== undefined) {
            console.log("Current profile picture: ", doc.data().pdp);
            setPdp(doc.data().pdp);
          } else {
            setPdp(undefined);
          }
        }
      });

      return () => {
        unsub(); // Unsubscribe from the snapshot listener when the component unmounts
      };
    }

    // Return a no-op cleanup function if currentUser is not defined
    return () => {};
  }, [currentUser, isMounted]);
  return (
    <div>
      <Navbar />
      <div className="flex justify-center items-center h-screen">
        <div className="bg-[#1e1e1e] w-[500px] rounded-2xl  ">
          <div className="m-[20px]">
            <div className="group/item w-[100px] h-[100px] relative flex items-center justify-center text-center">
              {pdp !== undefined ? (
                <img
                  className=" group/edit w-[100px] h-[100px] object-cover rounded-full group-hover/item:opacity-50 cursor-pointer"
                  onClick={clicked}
                  src={pdp}
                  alt=""
                />
              ) : (
                <img
                  className=" group/edit w-[100px] h-[100px] object-cover rounded-full group-hover/item:opacity-50 cursor-pointer"
                  onClick={clicked}
                  src={profilepic}
                  alt=""
                />
              )}

              <div
                className="group/edit select-none cursor-pointer absolute invisible group-hover/item:visible"
                onClick={clicked}
              >
                changer l'avatar
              </div>
            </div>
            <div className="bg-[#2d2d30] h-max rounded-xl flex-col justify-between items-center p-3 mt-4">
              <div className="flex h-max justify-between items-center mb-4">
                <div className="flex-col">
                  <div className="text-[#808080] text-[12px]">
                    NOM D'UTILISATEUR
                  </div>
                  <div className="text-white">selemskr</div>
                </div>
                <div className="">
                  <button className="bg-[#414142] px-3 py-2 text-[14px] rounded">
                    Modifier
                  </button>
                </div>
              </div>
              <div className="flex h-max justify-between items-center">
                <div className="flex-col">
                  <div className="text-[#808080] text-[12px]">E-MAIL</div>
                  <div className="text-white">{currentUser.email}</div>
                </div>
                <div className="">
                  <button className="bg-[#414142] px-3 py-2 text-[14px] rounded">
                    Modifier
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <PictureModal /> */}
      {modalOn && (
        <PictureModal setModalOn={setModalOn} setChoice={setChoice} />
      )}
      <SearchBar />
    </div>
  );
};

export default UserDashboard;
